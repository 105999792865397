import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Box,
  Grid,
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
} from "@mui/material";
import commaNumber from "comma-number";
import confirm from "../../images/Icon/confirm 1.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../images/loader/Render.gif";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import { Calendar } from "react-date-range";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Issue from "../../images/undraw/undraw_booking_re_gw4j.svg";
import "./Congratulation.css";
// import AllPDF from "../Queues/Queues/QueuesDetail/AllPdf";
import Header from "../Header/Header";
import FileUploadSection from "../Shared/FileUploadSection/FileUploadSection";
import { ToWords } from "to-words";
import flightData from "../flightData";
import FareRules from "./FareRules";
import AllPDF from "./ALLPDF/ALLPDF";
import AirlineNames from "./AirlineNames/AirlineNames";
import Address from "./Address/Address";
import axios from "axios";

const styles = {
  button: {
    padding: "6px 20px",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "var(--primary-color)",
    border: "none",
    cursor: "pointer",
  },
  buttonDisabled: {
    padding: "6px 20px",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "var(--primary-color)",
    border: "none",
    cursor: "not-allowed",
  },
};

const dateStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  borderRadius: "10px",
  overflow: "auto",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Congratulation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [downExpanded, setDownExpanded] = useState("panel1");
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setDownExpanded(newExpanded ? panel : false);
  };
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issueLoading, setIssueLoading] = useState(false);
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  let agentName = users?.user?.name;
  let staffName = users?.user?.name;
  let userStaffId = users?.user?.staffId;
  const [isDone, setIsDone] = useState(true);
  const [allData, setAllData] = useState([]);
  const [timeLimit, setTimeLimit] = useState([]);
  const [passengerData, setPassengerData] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);
  const [fareOpen, setFareOpen] = useState(false);
  const [fareStore, setFareStore] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  // Modal open Reissue Refund and void
  const [modalOpen, setModalOpen] = useState({
    quotation: false,
    value: "",
  });

  //todo:state change
  const [state, setState] = useState(false);
  // todo:for multicity
  //todo: calculate total flight duration
  const calDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  // !end
  const [lastBalance, setLastBalance] = useState(0);
  const toWords = new ToWords();
  const voideDate = new Date(allData?.lastUpdated);
  let today = new Date().toISOString().slice(0, 10);
  // const todaydate = new Date();
  const { pnr, gds } = location.state;

  // --------------------- client information start ---------------------------

  // visa and passport copy update state

  const updateModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    py: 3,
    px: 3,
    borderRadius: "10px",
    overflow: "auto",
  };

  const agentId = location?.state?.agentId;
  const bookingId = location?.state?.BookingId;
  const tripType = location?.state.tripType;
  const body = {
    agentId,
    bookingId,
  };
  const [flightData, setFlightData] = useState([]);
  const [fareTotal, setFareTotal] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [qeuesData, setQeuesData] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [stateChange, setStateChange] = useState(false);
  const [reissueData, setReIssueData] = useState();
  const [checkBox, setCheckBox] = useState();
  const [qoutationData, setQoutationData] = useState([]);

  const [requestModal, setRequestModal] = useState({
    modal: false,
    value: "",
  });

  const url = `https://api.flyjatt.com/v1/AirBooking/AirRetrive.php?bookingId=${bookingId}`;
  const url1 = `https://api.flyjatt.com/v1/Queues/index.php?bookingId=${bookingId}`;
  // End of visa and passport copy update
  useEffect(() => {
    // setIsLoading(true);
    fetch(url1)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        setStateData(data[0]);
        setPassengerData(data?.passenger);
        setQeuesData(data);
        setInvoiceId(data?.tickets);
      });

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (tripType === "multicity") {
          setIsLoading(true);
          setFlightData(data?.flights);
          setFareTotal(data?.fares);
          setAllData(data);
          // Only String Check Regex
          const regex = /^[a-zA-Z\s.,!?']+$/;

          let state = data?.specialServices?.filter((oths) => {
            return oths.code === "OTHS" && !regex.test(oths.message);
          });
          setTimeLimit(state);
        } else if (tripType === "return") {
          setIsLoading(true);
          // setPassengerData(data.travelers);
          setFlightData(data?.flights);
          setFareTotal(data?.fares);
          setAllData(data);
          // Only String Check Regex
          const regex = /^[a-zA-Z\s.,!?']+$/;

          let state = data?.specialServices?.filter((oths) => {
            return oths.code === "OTHS" && !regex.test(oths.message);
          });
          setTimeLimit(state);
        } else {
          setIsLoading(true);
          // setPassengerData(data.travelers);
          setFlightData(data?.flights);
          setFareTotal(data?.fares);
          setAllData(data);

          // Only String Check Regex
          const regex = /^[a-zA-Z\s.,!?']+$/;

          let state = data?.specialServices?.filter((oths) => {
            return oths.code === "OTHS" && !regex.test(oths.message);
          });
          setTimeLimit(state);
        }
      });

    setIsLoading(false);
  }, [bookingId, agentId, stateChange]);

  const cancelBooking = (system, pnr) => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Cancel this Flight ?",
      showCancelButton: true,
      confirmButtonColor: "var(--secondary-color)",
      confirmButtonText: "Yes Cancel it !",
      cancelButtonColor: "var(--primary-color)",
      cancelButtonText: "Don't Cancel it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = `https://api.flyjatt.com/v1/AirBooking/AirCancel.php`;
        let body = JSON.stringify({
          bookingId: bookingId,
          agentId: agentID,
        });
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              Swal.fire({
                title: "Your Flight is Cancel!",
                html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
                confirmButtonText: "OK",
                confirmButtonColor: "var(--primary-color)",
              }).then(() => {
                setIsDone(true);
                navigate("/user/queues");
              });
            } else {
              throw new Error("error");
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            Swal.fire({
              title: "Booking Cancel Failed!",
              html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
              confirmButtonText: "OK",
              cancelButtonColor: "var(--primary-color)",
            }).then(() => {
              setIsDone(true);
              navigate(0);
            });
          });
      }
    });
  };

  //--------------- Issue Ticket Start ------------------
  const issueData = {
    agentId: agentID || "Agent",
    bookingId: bookingId || "BookingId",
  };

  async function handleIssueTicket() {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You wants to issue this ticket ?",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Yes Issue it !",
      cancelButtonColor: "crimson",
      cancelButtonText: "Don't Issue it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = "https://api.flyjatt.com/v1/AirTicketing/IssueRequest.php";
        let body = JSON.stringify(issueData);
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status.toLowerCase() === "success") {
              Swal.fire({
                imageUrl: Issue,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `Ticket Issue Complete`,
                html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                setIsDone(true);
                setIssueLoading(false);
                navigate("/user/queues");
              });
            } else {
              Swal.fire({
                imageUrl: Invalid,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${data?.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(function () {
                setIsDone(true);
                setIssueLoading(false);
                navigate(0);
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              imageUrl: Invalid,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Issue Ticket Failed!",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsDone(true);
              setIssueLoading(false);
              navigate(0);
            });
          });
      }
    });
  }

  // Qoutation Show start
  const handleQutationsFetch = async (value) => {
    const body = {
      bookingId: bookingId,
      agentId: agentId,
    };
    await fetch(
      `https://api.flyjatt.com/v1/Ticketed/${value}.php?getquotadata`,

      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setQoutationData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Qoutation Show end
  // Qoutation Approve and Reject start
  const handleOption = async (value, option) => {
    // window.event.preventDefault();
    setIsDisabled(true);
    // setIsLoading(false);

    const body = {
      bookingId: bookingId,
      agentId: agentId,
      option: option,
    };

    await fetch(
      `https://api.flyjatt.com/v1/Ticketed/${value}.php?option`,

      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setModalOpen(false);
          setIsLoading(true);
          Swal.fire({
            icon: "success",
            title: `${data?.message}`,
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            setStateChange(true);
          });
        } else {
          setModalOpen(false);
          setIsLoading(true);
          Swal.fire({
            icon: "error",
            title: "Request Failed!",
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Qoutation Approve and Reject end

  let reissue = [];
  const handleCheckBox = (index) => {
    const e = window.event;
    const tempData = [...invoiceId];
    tempData[index] = { ...tempData[index], checkBox: e.target.checked };
    setCheckBox(e.target.checked);
    setInvoiceId(tempData);
  };

  const checkedData = invoiceId.filter((data) => {
    if (data.checkBox === true) {
      reissue = [
        ...reissue,
        { name: `${data.lastName + data.FirstName}`, ticket: data.ticketno },
      ];
    }
  });

  let reissueBody = JSON.stringify({
    agentId: agentId,
    staffId: userStaffId || "StaffId",
    bookingId: bookingId || "BookingId",
    requestedBy: staffName || agentName,
    passengerData: reissue,
    date: date,
  });

  let refundBody = JSON.stringify({
    agentId: agentId,
    staffId: userStaffId || "StaffId",
    bookingId: bookingId || "BookingId",
    requestedBy: staffName || agentName,
    passengerData: reissue,
  });

  let voidBody = JSON.stringify({
    agentId: agentId,
    bookingId: bookingId || "BookingId",
  });

  const handleReissue = async (e) => {
    setIsDisabled(true);
    setIsLoading(false);
    e.preventDefault();
    const reissueRefund = `https://api.flyjatt.com/v1/Ticketed/${requestModal?.value}.php?request`;
    const voidurl = "https://api.flyjatt.com/v1/AirBooking/AirVoid.php";
    await fetch(`${requestModal?.value === "Void" ? voidurl : reissueRefund}`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },

      body: `${
        requestModal?.value === "Reissue"
          ? reissueBody
          : requestModal?.value === "Refund"
          ? refundBody
          : voidBody
      }`,
    })
      .then((res) => res.json())
      .then((data) => {
        setRequestModal({ modal: false });
        if (data?.status === "success") {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: `${data?.message}`,
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            setStateChange(true);
            setRequestModal({ modal: false });
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: `${data?.message}`,
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            setStateChange(true);
            setRequestModal({ modal: false });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    e.target.reset();
  };

  //  all passenger total price calculation
  const adultTotalPrice =
    parseInt(allData?.adultCostBase) + parseInt(allData?.adultCostTax);
  const childTotalPrice =
    parseInt(allData?.childCostBase) + parseInt(allData?.childCostTax);
  const infantTotalPrice =
    parseInt(allData?.infantCostBase) + parseInt(allData?.infantCostTax);
  const totalPrice = adultTotalPrice + childTotalPrice + infantTotalPrice;
  const savingMoney = Math.abs(
    parseInt(allData?.grossCost) - parseInt(allData?.netCost)
  );
  //todo remove last word function

  // const handleCheckBox = (index) => {
  //   const e = window.event;
  //   const tempData = [...invoiceId];
  //   tempData[index] = { ...tempData[index], checkBox: e.target.checked };
  //   setCheckBox(e.target.checked);
  //   setInvoiceId(tempData);
  // };

  // fare details start

  const handleClickfareDetails = async () => {
    setFareOpen(true);

    const Segmentss = [
      {
        DepDate: stateData?.journeyDate,
        DepFrom: stateData?.DepFrom,
        ArrTo: stateData?.ArrTo,
        Airlines: qeuesData?.data?.flights[0]?.airlineCode || "",
      },
    ];
    let farebody = JSON.stringify({
      FareBasis: `${qeuesData?.data?.fares[0]?.fareConstruction[0]?.fareBasisCode}`,
      Segments: Segmentss,
    });
    // data.fares[0].fareConstruction[0].fareBasisCode
    // console.log("farebody", farebody);
    const fareurl = "https://api.flyjatt.com/v1/AirFareRules/TextRules.php";
    await fetch(fareurl, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },

      body: farebody,
    })
      .then((res) => res.json())
      .then((data) => {
        setFareStore(data);
        // console.log("new Fare Data", data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // fare details end

  if (!isDone) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  let count = 0;
  const passengerCount = (p) => {
    count = count + p;
    return count;
  };

  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 4, md: 2 } }}>
        <Box position={"relative"}>
          {isLoading ? (
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={9.5}>
                <Box sx={{ marginTop: "20px" }}>
                  {location?.state?.path === "queues" ? null : (
                    <Box display="flex" my={3}>
                      <Box marginRight={2}>
                        <img src={confirm} alt="..." />
                      </Box>
                      <Box>
                        <Typography variant="h5" color="var(--green-color)">
                          Thank You. Your Booking is Confirmed
                        </Typography>
                        <Typography>
                          A Confirmation email has been sent to your provided
                          email address.
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "fit-content",
                        background: "var(--primary-color)",
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "10px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Booking Id: {bookingId || "BookingId"}
                    </Typography>
                    <Typography
                      sx={{
                        width: "fit-content",
                        background: "var(--primary-color)",
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "10px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      {stateData?.Status}
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        width: "100%",
                        background: "var(--third-color)",
                        color: "var(--secondary-color)",
                        padding: "2px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Flight Information
                    </Typography>

                    <Box
                      mt={2}
                      className="table-wrapper"
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "0px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "var(--secondary-color)",
                          borderRadius: "0px",
                        },
                        overflowY: "scroll",
                      }}
                    >
                      <table className="responsive-table congra">
                        <thead>
                          <tr>
                            <th width="15%">Flight</th>
                            <th width="23%">Departure From</th>
                            <th width="23%">Arrival To</th>
                            <th width="12%">Depart At</th>
                            <th width="12%">Arrive At</th>
                            <th width="15%">Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          {flightData?.length === 0 ? (
                            <Box>Loading...</Box>
                          ) : (
                            <>
                              {flightData?.map((data, index) => (
                                <tr>
                                  <td>
                                    <AirlineNames code={data?.airlineCode} />{" "}
                                    {data?.airlineCode}-{data?.flightNumber}
                                    <br />
                                    {data?.aircraftTypeName}
                                  </td>
                                  <td>
                                    ({data.fromAirportCode}){" "}
                                    <Address code={data.fromAirportCode} />
                                    <br />
                                    {data?.departureTerminalName || null}{" "}
                                    {data?.departureGate
                                      ? `Gate: ${data?.departureGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    ({data.toAirportCode}){" "}
                                    <Address code={data.toAirportCode} />
                                    <br />
                                    {data?.arrivalTerminalName || null}{" "}
                                    {data?.arrivalGate
                                      ? `Gate: ${data?.arrivalGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    {data.departureDate}{" "}
                                    {data.departureTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {data.arrivalDate}{" "}
                                    {data.arrivalTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {data?.cabinTypeName?.toLowerCase()}-
                                    {data?.bookingClass}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        width: "100%",
                        background: "var(--third-color)",
                        color: "var(--secondary-color)",
                        padding: "2px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Price BreakDown
                    </Typography>
                    {/* <Box mt={2} className="flight-queue-detail">
                      {fareTotal?.length === 0 ? (
                        <>Loading...</>
                      ) : (
                        <Box mt={2}>
                          <Box>
                            <table>
                              <tr>
                                <th width="20%">Passenger </th>
                                <th width="20%">Base Fare </th>
                                <th width="20%">Tax</th>
                                <th width="20%">Sub-Total Fare</th>
                                <th width="20%">Baggage</th>
                              </tr>

                              {fareTotal?.map((data, index) => (
                                <tr>
                                  <td>
                                    {data?.pricedTravelerType === "ADT"
                                      ? "Adult"
                                      : data?.pricedTravelerType === "INF"
                                      ? "Infant"
                                      : "Child"}
                                    {" X "}
                                    {data?.travelerIndices?.length || 1}
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.subtotal) *
                                      parseInt(
                                        data?.travelerIndices?.length || 1
                                      )}{" "}
                                    SAR
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.taxes) *
                                      parseInt(
                                        data?.travelerIndices?.length || 1
                                      )}{" "}
                                    SAR
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.total) *
                                      parseInt(
                                        data?.travelerIndices?.length || 1
                                      )}{" "}
                                    SAR
                                  </td>
                                  <td>
                                    {data?.fareConstruction[0]
                                      ?.checkedBaggageAllowance
                                      ?.totalWeightInKilograms
                                      ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                                      : null}
                                    {data?.fareConstruction[0]
                                      ?.checkedBaggageAllowance?.maximumPieces
                                      ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                                      : null}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </Box>

                          <table className="flight-queue-detail table-data">
                            <tr>
                              <td width="20%">
                                Total Passenger{" "}
                                <span style={{ display: "none" }}>
                                  {fareTotal?.map((data, index) =>
                                    passengerCount(
                                      data?.travelerIndices?.length
                                    )
                                  )}
                                </span>
                                {count || 1}
                              </td>
                              <td width="20%">
                                Total Fare{" "}
                                {allData?.payments?.flightTotals[0]?.subtotal}{" "}
                                SAR
                              </td>
                              <td width="20%">
                                Total Taxes{" "}
                                {allData?.payments?.flightTotals[0]?.taxes} SAR
                              </td>
                              <td width="40%">
                                Total Amount{" "}
                                {allData?.payments?.flightTotals[0]?.total} SAR
                              </td>
                            </tr>
                          </table>
                        </Box>
                      )}
                    </Box> */}

                    <Box
                      mt={2}
                      className="table-wrapper"
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "0px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "var(--secondary-color)",
                          borderRadius: "0px",
                        },
                        overflowY: "scroll",
                      }}
                    >
                      <table className="responsive-table congra">
                        <thead>
                          <tr>
                            <th width="20%">Passenger </th>
                            <th width="20%">Base Fare </th>
                            <th width="20%">Tax</th>
                            <th width="20%">Sub-Total Fare</th>
                            <th width="20%">Baggage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fareTotal?.length === 0 ? (
                            <>Loading...</>
                          ) : (
                            <>
                              {fareTotal?.map((data, index) => (
                                <tr>
                                  <td>
                                    {data?.pricedTravelerType === "ADT"
                                      ? "Adult"
                                      : data?.pricedTravelerType === "INF"
                                      ? "Infant"
                                      : "Child"}
                                    {" X "}
                                    {data?.travelerIndices?.length || 1}
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.subtotal) *
                                      parseInt(
                                        data?.travelerIndices?.length || 1
                                      )}{" "}
                                    SAR
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.taxes) *
                                      parseInt(
                                        data?.travelerIndices?.length || 1
                                      )}{" "}
                                    SAR
                                  </td>
                                  <td>
                                    {parseInt(data?.totals?.total) *
                                      parseInt(
                                        data?.travelerIndices?.length || 1
                                      )}{" "}
                                    SAR
                                  </td>
                                  <td>
                                    {data?.fareConstruction[0]
                                      ?.checkedBaggageAllowance
                                      ?.totalWeightInKilograms
                                      ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                                      : null}
                                    {data?.fareConstruction[0]
                                      ?.checkedBaggageAllowance?.maximumPieces
                                      ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                                      : null}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                          <tr>
                            <td>
                              Total Passenger{" "}
                              <span style={{ display: "none" }}>
                                {fareTotal?.map((data, index) =>
                                  passengerCount(data?.travelerIndices?.length)
                                )}
                              </span>
                              {count || 1}
                            </td>
                            <td>
                              Total Fare{" "}
                              {allData?.payments?.flightTotals[0]?.subtotal} SAR
                            </td>
                            <td>
                              Total Taxes{" "}
                              {allData?.payments?.flightTotals[0]?.taxes} SAR
                            </td>
                            <td colSpan={2}>
                              Total Amount{" "}
                              {allData?.payments?.flightTotals[0]?.total} SAR
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                  <Box my={3}>
                    <Typography
                      sx={{
                        width: "100%",
                        background: "var(--third-color)",
                        color: "var(--secondary-color)",
                        padding: "2px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Passenger Information
                    </Typography>
                    <Box mt={2} className="queue-detail-passenger-detail">
                      <div>
                        {passengerData?.length === 0 ? (
                          <>
                            {qeuesData?.data?.travelers?.map((traveler) => (
                              <Box>
                                <Box
                                  p="3px 5px"
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  my="2px"
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <h5
                                    style={{
                                      color: "var(--secondary-color)",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {traveler?.givenName?.slice(
                                      traveler?.givenName?.lastIndexOf(" ")
                                    )}{" "}
                                    {traveler?.givenName?.slice(
                                      0,
                                      traveler?.givenName?.lastIndexOf(" ") ||
                                        "First Name"
                                    )}{" "}
                                    {traveler?.surname || "Last Name"}
                                  </h5>
                                </Box>

                                <Box
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  p="3px 5px"
                                  mb={2}
                                >
                                  <Grid container spacing={1}>
                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>First Name</h5>
                                      <h6>
                                        {traveler?.givenName?.slice(
                                          0,
                                          traveler?.givenName?.lastIndexOf(
                                            " "
                                          ) || "First Name"
                                        )}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Last Name</h5>
                                      <h6>
                                        {traveler?.surname || "Last Name"}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={2}>
                                      <h5>Nationality</h5>
                                      <h6>
                                        {(traveler?.identityDocuments &&
                                          traveler?.identityDocuments[0]
                                            ?.residenceCountryCode) ||
                                          "Nationality"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Date of Birth</h5>
                                      <h6>
                                        {traveler?.identityDocuments &&
                                        traveler?.identityDocuments[0]
                                          ?.birthDate
                                          ? format(
                                              new Date(
                                                traveler?.identityDocuments[0]?.birthDate
                                              ),
                                              "dd MMM yyyy"
                                            )
                                          : "Date of Birth"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Gender</h5>
                                      <h6>
                                        {(traveler?.identityDocuments &&
                                          traveler?.identityDocuments[0]
                                            ?.gender) ||
                                          "Gender"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Pax Type</h5>
                                      <h6>{traveler?.type || "Type"}</h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Passport Number</h5>
                                      <h6>
                                        {(traveler?.identityDocuments &&
                                          traveler?.identityDocuments[0]?.documentNumber?.toUpperCase()) ||
                                          "Domestic Flight"}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>p. Expire Date</h5>

                                      <h6>
                                        {traveler?.identityDocuments &&
                                        traveler?.identityDocuments[0]
                                          ?.expiryDate
                                          ? format(
                                              new Date(
                                                traveler?.identityDocuments[0]?.expiryDate
                                              ),
                                              "dd MMM yyyy"
                                            )
                                          : "Expire Date"}
                                      </h6>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <Box>
                            {passengerData?.map((traveler, index) => (
                              <Box>
                                <Box
                                  p="3px 5px"
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  my="2px"
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <h5
                                    style={{
                                      color: "var(--secondary-color)",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {traveler?.gender === "Male" ? (
                                      <>
                                        {traveler?.gender === "Male" &&
                                        traveler?.type === "ADT" ? (
                                          <>
                                            MR {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        ) : (
                                          <>
                                            MSTR {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {traveler?.gender === "Female" &&
                                        traveler?.type === "ADT" ? (
                                          <>
                                            MS {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        ) : (
                                          <>
                                            MISS {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </h5>
                                </Box>

                                <Box
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  p="3px 5px"
                                  mb={2}
                                >
                                  <Grid container spacing={1}>
                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Title</h5>
                                      <h6>
                                        {traveler?.gender === "Male" ? (
                                          <>
                                            {traveler?.gender === "Male" &&
                                            traveler?.type === "ADT" ? (
                                              <>MR</>
                                            ) : (
                                              <>MSTR</>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {traveler?.gender === "Female" &&
                                            traveler?.type === "ADT" ? (
                                              <>MS</>
                                            ) : (
                                              <>MISS</>
                                            )}
                                          </>
                                        )}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>First Name</h5>
                                      <h6>{traveler?.fName}</h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>Last Name</h5>
                                      <h6>{traveler?.lName}</h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>Nationality</h5>
                                      <h6>{traveler?.passNation}</h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Date of Birth</h5>
                                      <h6>
                                        {traveler?.dob
                                          ? format(
                                              new Date(traveler?.dob),
                                              "dd MMM yyyy"
                                            )
                                          : "Date of Birth"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Gender</h5>
                                      <h6>{traveler?.gender}</h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Pax Type</h5>
                                      <h6>
                                        {traveler?.type === "ADT"
                                          ? "Adult"
                                          : traveler?.type === "CNN"
                                          ? "Child"
                                          : "Infat"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>Passport Number</h5>
                                      <h6>
                                        {traveler?.passNo === "" ||
                                        traveler?.passNo === null
                                          ? "Domestic Flight"
                                          : traveler?.passNo?.toUpperCase()}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={2}>
                                      <h5>P. Expire Date</h5>

                                      <h6>
                                        {traveler?.passEx === "" ||
                                        traveler?.passEx === null
                                          ? "Domestic Flight"
                                          : traveler?.passEx?.toUpperCase()}
                                      </h6>
                                    </Grid>

                                    {allData?.flightTickets ===
                                    undefined ? null : (
                                      <Grid item xs={6} sm={3} md={2}>
                                        <h5>Ticket Number</h5>

                                        <h6>
                                          {
                                            allData?.flightTickets[index]
                                              ?.number
                                          }
                                        </h6>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </div>
                    </Box>
                    {/* passenger information end */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2.5} mt={1.5}>
                <Box className="accordian-expand">
                  {stateData?.Status === "Hold" ? (
                    <Accordion
                      expanded={downExpanded === "panel1"}
                      onChange={handleChangeDown("panel1")}
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8bh-content"
                        id="panelbh-header"
                      >
                        <Typography
                          style={{
                            color: "var(--primary-color)",
                            fontFamily: "poppins",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Time Limit
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {timeLimit?.length === 0 ? (
                          "No Time Limit"
                        ) : (
                          <>
                            {timeLimit?.map((data, index) => (
                              <Box key={index}>
                                <Typography
                                  sx={{
                                    color: "#222222",
                                    fontSize: 12,
                                    my: 1,
                                    fontWeight: 500,
                                  }}
                                >
                                  {data?.message || "No Time Limit"}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : null}

                  {/* //todo:all buttons */}
                  {stateData?.Status === "Ticketed" ||
                  stateData?.Status === "Reissued" ? (
                    <>
                      <Box className="queues-detail-calcel-btn" my={1}>
                        <button
                          style={{
                            padding: "8px 20px",
                            color: "#fff",
                            backgroundColor: "var(--primary-color)",
                            border: "none",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={(e) => {
                            setRequestModal({
                              modal: true,
                              value: "Reissue",
                            });
                          }}
                        >
                          Re-Issue
                        </button>
                      </Box>
                      {allData?.fareRules === undefined ? null : allData
                          ?.fareRules[0]?.isRefundable === true ? (
                        <Box className="queues-detail-calcel-btn" mb={1}>
                          <button
                            style={{
                              padding: "8px 20px",
                              color: "#fff",
                              backgroundColor: "var(--primary-color)",
                              border: "none",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            onClick={(e) => {
                              setRequestModal({
                                modal: true,
                                value: "Refund",
                              });
                            }}
                          >
                            Refund
                          </button>
                        </Box>
                      ) : (
                        ""
                      )}

                      {stateData?.Status === "Ticketed" &&
                      today <= stateData?.updated_at ? (
                        <Box className="queues-detail-calcel-btn">
                          <button
                            style={{
                              padding: "8px 20px",
                              color: "#fff",
                              backgroundColor: "var(--primary-color)",
                              border: "none",
                              cursor: "pointer",
                              width: "100%",
                            }}
                            onClick={(e) => {
                              setRequestModal({
                                modal: true,
                                value: "Void",
                              });
                            }}
                          >
                            Void
                          </button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  ) : stateData?.Status === "Issue In Processing" ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        bgcolor: "var(--secondary-color)",
                        py: 1,
                        textAlign: "center",
                      }}
                    >
                      Wait For Ticketed
                    </Typography>
                  ) : stateData?.Status === "Refund In Processing" ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        bgcolor: "var(--secondary-color)",
                        py: 1,
                        textAlign: "center",
                      }}
                    >
                      Wait For Refunded
                    </Typography>
                  ) : stateData?.Status === "Reissue In Processing" ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        bgcolor: "var(--secondary-color)",
                        py: 1,
                        textAlign: "center",
                      }}
                    >
                      Wait For Reissued
                    </Typography>
                  ) : stateData?.Status === "Void In Processing" ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        bgcolor: "var(--secondary-color)",
                        py: 1,
                        textAlign: "center",
                      }}
                    >
                      Wait For Voided
                    </Typography>
                  ) : (
                    <>
                      {stateData?.Status === "Hold" && (
                        <Box>
                          <Box my={1}>
                            <button
                              style={{
                                padding: "6px",
                                width: "100%",
                                cursor: "pointer",
                                border: "none",
                                backgroundColor: "var(--primary-color)",
                              }}
                              onClick={() => handleIssueTicket()}
                            >
                              Issue Ticket
                            </button>
                          </Box>
                          <Box my={1}>
                            <button
                              style={{
                                padding: "6px",
                                width: "100%",
                                cursor: "pointer",
                                border: "none",
                                backgroundColor: "red",
                              }}
                              onClick={() => cancelBooking(gds, pnr)}
                            >
                              Cancel Flight
                            </button>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                  {stateData?.Status === "Reissue Quotation Send" && (
                    <Typography
                      className="issueTicket-btn"
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "#fff",
                        my: 2,
                        py: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setModalOpen({
                          quotation: "true",
                          value: "Reissue",
                        });
                        handleQutationsFetch("Reissue");
                      }}
                    >
                      View Quotation
                    </Typography>
                  )}
                  {stateData?.Status === "Refund Quotation Send" && (
                    <Typography
                      className="issueTicket-btn"
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "#fff",
                        my: 2,
                        py: 1,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setModalOpen({
                          quotation: "true",
                          value: "Refund",
                        });
                        handleQutationsFetch("Refund");
                      }}
                    >
                      View Refund Quotation
                    </Typography>
                  )}
                  <Box mt={2}>
                    <AllPDF
                      isLoading
                      tripType={tripType}
                      agentID={agentID}
                      bookingId={bookingId}
                      flightData={flightData}
                      fareTotal={fareTotal}
                      passengerData={passengerData}
                      allData={allData}
                      stateData={stateData}
                      qeuesData={qeuesData}
                    />
                  </Box>
                  <Box py={2}>
                    <Accordion
                      expanded={downExpanded === "panel11"}
                      onChange={handleChangeDown("panel11")}
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8bh-content"
                        id="panelbh-header"
                      >
                        <Typography
                          style={{
                            color: "var(--primary-color)",
                            fontFamily: "poppins",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          PNR History
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {qeuesData?.activity?.map((data, index) => (
                          <Grid container key={index}>
                            <Grid item xs={1}>
                              <Box
                                width="14px"
                                height="14px"
                                bgcolor="var(--primary-color)"
                                position="relative"
                                ml={-1}
                              ></Box>
                            </Grid>
                            <Grid item mt="-3px" xs={11}>
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {data?.status}
                              </Typography>
                              <Box py={2}>
                                <Typography
                                  sx={{
                                    color: "#70A5D8",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {data?.actionBy || ""}
                                  {data?.status === "Hold" ||
                                  data?.status === "Issue In Processing" ||
                                  data?.status === "Reissue In Processing" ||
                                  data?.status === "Refund In Processing" ||
                                  data?.status === "Void In Processing" ? (
                                    <>
                                      {data?.actionBy === "" ? "" : ","}{" "}
                                      {/* {companyname || ""} */}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#767676",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {new Date(data?.actionAt).toLocaleString(
                                    "en-GB",
                                    {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: false,
                                    }
                                  )}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "crimson",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    mt: "4px",
                                  }}
                                >
                                  {data?.remarks === "" ||
                                  data?.remarks === " " ||
                                  data?.remarks === null ? (
                                    ""
                                  ) : (
                                    <>Remarks: {`${data?.remarks}`}</>
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={downExpanded === "panel2"}
                      onChange={handleChangeDown("panel2")}
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                        margin: "0",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8bh-content"
                        id="panelbh-header"
                        style={{ margin: "0" }}
                      >
                        <Typography
                          style={{
                            color: "var(--primary-color)",
                            fontFamily: "poppins",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Fare rules
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{
                          margin: "0",
                        }}
                      >
                        <Box>
                          {allData?.fareRules === undefined ? (
                            ""
                          ) : allData?.fareRules?.length === 0 ? (
                            "Loading Data..."
                          ) : allData?.fareRules?.length >= 1 ? (
                            <Box>
                              {allData?.fareRules?.map((data) => (
                                <FareRules data={data} />
                              ))}
                            </Box>
                          ) : (
                            "No fare rules this flight"
                          )}
                        </Box>
                        <Typography
                          sx={{
                            bgcolor: "#222222",
                            color: "#fff",
                            py: 1,
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={handleClickfareDetails}
                        >
                          Fare Details
                        </Typography>
                        <Box>
                          <Modal
                            open={fareOpen}
                            onClose={() => setFareOpen(false)}
                            aria-labelledby="parent-modal-title"
                            aria-describedby="parent-modal-description"
                          >
                            <Box
                              sx={{
                                ...modalStyle,
                                width: { xs: "300px", sm: "400px", md: 700 },
                                overflowY: "auto",
                                height: 400,
                              }}
                              style={{
                                border: "none",
                                borderRadius: "5px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#222222",
                                  fontSize: 12,
                                  textAlign: "center",
                                }}
                              >
                                Fare Details
                              </Typography>
                              <Typography
                                sx={{ color: "#222222", fontSize: 10, mt: 2 }}
                              >
                                {fareStore?.length === 0 ? (
                                  "No Fare Details"
                                ) : (
                                  <>
                                    {fareStore?.map((data) => (
                                      <Box my={1}>{data?.rules}</Box>
                                    ))}
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </Modal>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "100%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* ---------Modal Refund, Request, void Request start--------- */}

        <Box className="queues-detail-calcel-btn">
          <Modal
            open={requestModal.modal}
            onClose={() => setRequestModal({ modal: false })}
          >
            <Box
              bgcolor="#fff"
              sx={{
                ...updateModalStyle,
                width: { xs: "100%", sm: "80%", md: "40%" },
                p: { xs: 1, sm: 3 },
              }}
            >
              <Box className="modal-table">
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: "20px",
                    fontWeight: 500,
                    mb: "10px",
                  }}
                >
                  Make {requestModal.value} Request
                </Typography>

                <table width="100%">
                  <thead>
                    <tr>
                      <th width="5%">Select</th>
                      <th width="25%">Passenger&nbsp;Name</th>
                      <th width="20%">Gender</th>
                      <th width="20%">Passenger&nbsp;Type</th>
                      <th width="30%">Ticket No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {passengerData?.map((traveler, index) => (
                      <tr key={index}>
                        <td width="10px" style={{ border: "none" }}>
                          <FormGroup
                            style={{
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    padding: "0px",
                                    margin: "auto",
                                    marginLeft: "20px",
                                  }}
                                  onChange={() => handleCheckBox(index)}
                                />
                              }
                            />
                          </FormGroup>
                        </td>
                        <td>
                          {traveler?.gender === "Male" ? (
                            <>
                              {traveler?.gender === "Male" &&
                              traveler?.type === "ADT" ? (
                                <>
                                  MR {traveler?.fName} {traveler?.lName}
                                </>
                              ) : (
                                <>
                                  MSTR {traveler?.fName} {traveler?.lName}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {traveler?.gender === "Female" &&
                              traveler?.type === "ADT" ? (
                                <>
                                  MS {traveler?.fName} {traveler?.lName}
                                </>
                              ) : (
                                <>
                                  MISS {traveler?.fName} {traveler?.lName}
                                </>
                              )}
                            </>
                          )}
                        </td>
                        <td>{traveler?.gender}</td>
                        <td>
                          {traveler?.type === "ADT"
                            ? "Adult"
                            : traveler?.type === "CNN"
                            ? "Child"
                            : "Infat"}
                        </td>
                        <td>
                          {allData?.flightTickets === undefined
                            ? null
                            : allData?.flightTickets[index]?.number ||
                              "ticket number"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Grid item mt={2}>
                    {requestModal.value === "Void" ||
                    requestModal.value === "Refund" ? null : (
                      <Box>
                        <label htmlFor="date">Select Date</label> <br />
                        <Box>
                          <input
                            style={{
                              border: "2px solid #C4C4C4",
                              padding: "5px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            required
                            type="text"
                            name="date"
                            readOnly
                            value={format(new Date(date), "dd MMM yyyy")}
                            onClick={() => {
                              setOpenDate((prev) => !prev);
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item mt={4}>
                    <Box>
                      {isDisabled ? (
                        <button
                          style={
                            isDisabled ? styles.buttonDisabled : styles.button
                          }
                        >
                          Wait For Response
                        </button>
                      ) : (
                        <button
                          disabled={!checkBox === true}
                          style={
                            isDisabled ? styles.buttonDisabled : styles.button
                          }
                          onClick={handleReissue}
                        >
                          Submit
                        </button>
                      )}

                      <button
                        style={{
                          padding: "6px 20px",
                          color: "#fff",
                          backgroundColor: "red",
                          border: "none",
                          cursor: "pointer",
                        }}
                        type="reset"
                        onClick={() =>
                          setRequestModal({
                            modal: false,
                          })
                        }
                      >
                        Cancel
                      </button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* </from> */}
          </Modal>
          <Modal open={openDate} onClose={() => setOpenDate(false)}>
            <Box sx={dateStyle}>
              <Calendar
                color={"#222222"}
                date={new Date(date)}
                onChange={(date) => {
                  setDate(new Date(date).toLocaleDateString("sv"));
                  setOpenDate(false);
                }}
                minDate={new Date(date)}
                months={1}
                direction="horizontal"
                // className="reissue-calendar"
                name="dashboard-calendar"
              />
            </Box>
          </Modal>
        </Box>

        {/* ---------Modal Refund, Request, void Request start--------- */}
        {/* ---------Quatiton Modla start--------- */}
        <Box>
          <Modal
            open={modalOpen.quotation}
            onClose={() =>
              setModalOpen({
                quotation: false,
              })
            }
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{ ...modalStyle, width: 500 }}
              style={{
                border: "none",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "#003566",
                  fontSize: "20px",
                  mb: 1,
                }}
              >
                <Box sx={{ fontSize: "20px", fontWeight: 500, mb: 1 }}>
                  Quotation
                </Box>

                {modalOpen?.value === "Refund" ? (
                  <Box
                    sx={{ fontSize: "16px", fontWeight: 500, color: "#000" }}
                  >
                    Refund Service Charges: {qoutationData[0]?.quotamount}
                  </Box>
                ) : (
                  <Box>
                    <Box
                      sx={{ fontSize: "16px", fontWeight: 500, color: "#000" }}
                    >
                      Reissue Amount: {qoutationData[0]?.quotamount}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#000",
                        mb: 5,
                      }}
                    >
                      Description: {qoutationData[0]?.quottext}
                    </Typography>
                  </Box>
                )}
              </Typography>

              <Box className="balance-transaction">
                <Stack direction="row" justifyContent="flex-end">
                  <Box>
                    {!isDisabled ? (
                      <Button
                        onClick={() => handleOption(modalOpen?.value, "yes")}
                        size="small"
                        disabled={isDisabled}
                        style={
                          isDisabled ? styles.buttonDisabled : styles.button
                        }
                      >
                        Approve
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        disabled={isDisabled}
                        style={
                          isDisabled ? styles.buttonDisabled : styles.button
                        }
                      >
                        Wait For Response
                      </Button>
                    )}
                    {!isDisabled ? (
                      <Button
                        size="medium"
                        sx={{
                          color: "#fff",
                          bgcolor: "red",
                          "&:hover": {
                            bgcolor: "red",
                          },
                        }}
                        onClick={() => handleOption(modalOpen?.value, "no")}
                      >
                        Decline
                      </Button>
                    ) : null}
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Modal>
        </Box>
        {/* ---------Quatiton Modla end--------- */}

        {/* //todo: Update Document Modal */}
        {/* <Modal open={openUpdateModal} onClose={handleCloseUpdateModal}>
          <Box sx={updateModalStyle}>
            <FileUploadSection
              setState={setState}
              passengerData={allData?.passenger}
              handleIssueTicket={handleIssueTicket}
              handleCloseUpdateModal={handleCloseUpdateModal}
            />
          </Box>
        </Modal> */}
      </Container>
    </Box>
  );
};

export default Congratulation;
