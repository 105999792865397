import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import seat1 from "../../images/Icon/bag.svg";
import bag from "../../images/Icon/seat.svg";
import anemy from "../../images/anemy.png";
import FlightUserInfo from "../../components/FlightUserinfo/FlightUserInfo";
import FlightUserInfoFlyHub from "../../components/FlightUserinfo/FlightUserInfoFlyHub";
import FlightUserInfoSabre from "../../components/FlightUserinfo/FlightUserInfoSabre";
import Loader from "../../images/loader/Render.gif";
import NotFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import FlightInfoDetails from "../../components/FlightInfoDetails/FlightInfoDetails";
import FlightIcon from "@mui/icons-material/Flight";
import "./FlightInformation.css";
import { format } from "date-fns";
import Header from "../../components/Header/Header";
import FlightDetails from "../../components/SingleFlight/FlightDetails";

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const FlightInformation = (props) => {
  const location = useLocation();

  const [loadData, setLoadData] = useState([]);
  const { adultCount, childCount, infant } = location.state;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  //todo: cupon
  const [coupon, setCoupon] = useState("");
  const [couponAppliedMessage, setCouponAppliedMessage] = useState({});
  //todo:end cupon
  //todo: Baggage Information
  const [adultBaggage, setAdultBaggage] = useState(0);
  const [childBaggage, setChildBaggage] = useState(0);
  const [infantBaggage, setInfantBaggage] = useState(0);
  //todo: End Baggage Information end

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // console.log("flightinformation", location.state?.flightData);
  // console.log("flightinformation data", location.state?.data);
  const data = location.state?.flightData;
  delete data.More;

  let url = "https://api.flyjatt.com/v1/Flight/airprice.php";
  let body = data;
  console.log("body", body);

  useEffect(() => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("response", data);
        if (data?.status === "success") {
          setIsLoaded(false);
          setLoadData(data);
        } else {
          throw new Error(data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          imageUrl: NotFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: `${data?.message}`,
          confirmButtonText: "Search Another Flights...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate(-1);
        });
      });
  }, []);

  return (
    <Box>
      <Header />
      {!isLoaded ? (
        <Container sx={{ mt: 2 }}>
          <Grid container mt={4} spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8.5}
              order={{ xs: 2, sm: 2, md: 1 }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: 16, sm: 22 },
                    color: "var(--secondary-color)",
                    fontWeight: 500,
                  }}
                >
                  Flight Information Details
                </Typography>
                <Box>
                  {data?.AllLegs?.map((data, i, arr) => (
                    <Box my={2} key={i}>
                      {data?.Segments?.map((item, j) => (
                        <Box key={j} my={2}>
                          <FlightDetails data={item} />
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
                <Box>
                  <FlightUserInfoSabre
                    adultCount={adultCount}
                    childCount={childCount}
                    infantCount={infant}
                    flightData={data}
                    loadData={loadData}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                  />
                </Box>
                {/* <FlightUserInfoSabre
                  loadData={loadData}
                  flightData={location.state.flightData}
                  userData={location.state}
                  searchResult={loadData}
                  adultPrice={adultPrice}
                  childPrice={childPrice}
                  infPrice={infPrice}
                  adultTaxPrice={adultTaxPrice}
                  childTaxPrice={childTaxPrice}
                  infTaxPrice={infTaxPrice}
                  serviceFeeAdult={serviceFeeAdult}
                  serviceFeeChild={serviceFeeChild}
                  serviceFeeInfant={serviceFeeInfant}
                  inTotalBaseFare={inTotalBaseFare}
                  totalBaseFare={totalBaseFare}
                  totalTax={totalTax}
                  totalFare={totalFare}
                  limitTime={limitTime}
                  isLoaded={isLoaded}
                  setIsLoaded={setIsLoaded}
                  clientFare={location.state.clientFare}
                  coupon={coupon}
                  setCoupon={setCoupon}
                  couponAppliedMessage={couponAppliedMessage}
                  setCouponAppliedMessage={setCouponAppliedMessage}
                  adultBaggage={adultBaggage}
                  setAdultBaggage={setAdultBaggage}
                  childBaggage={childBaggage}
                  setChildBaggage={setChildBaggage}
                  infantBaggage={infantBaggage}
                  setInfantBaggage={setInfantBaggage}
                /> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3.5}
              order={{ xs: 1, sm: 1, md: 3 }}
            >
              <FlightInfoDetails
                adultCount={adultCount}
                childCount={childCount}
                infantCount={infant}
                flightData={data}
                loadData={loadData}
                isLoaded={isLoaded}
                setIsLoaded={setIsLoaded}
              />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
            width: "50vw",
            marginInline: "auto",
          }}
        >
          <Box
            style={{
              width: "50%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Loader}
              alt="loader"
              style={{
                width: "100%",
                objectFit: "center",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default FlightInformation;
