import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./components/Contexts/AuthProvider";
import Traveller from "./pages/Traveller/Traveller";
import AddTraveller from "./components/Traveller/AddTraveller";
import Deposite from "./pages/Deposite/Deposite";
import AddDeposite from "./components/Deposit/AddDeposite";
import SearchResult from "./pages/SearchReslut/SearchResult";
import FlightInformation from "./pages/FligthInformation/FlightInformation";
import RoundSearchResult from "./pages/SearchReslut/RoundSearchResult";
import RoundFlightUserInfo from "./components/FlightUserinfo/RoundFlightUserInfo";
import AddBank from "./components/BankAccount/AddBank";
import SignUp from "./components/SignUp/SignUp";
import MultiCitySearchResult from "./components/MultiCitySearchResult/MultiCitySearchResult";
import Dashboard from "./components/Dashboard/Dashboard";
import Congratulation from "./components/Congratulation/Congratulation";
import NotFound from "./components/NotFound/NotFound";
import SignIn from "./components/SignIn/SignIn";
import { Box } from "@mui/material";

import UserDashboardSideBar from "./components/UserDashboardSideBar/UserDashboardSideBar";
import TransectionReport from "./components/UserReport/TransectionReport";
import LedgerReport from "./components/UserReport/LedgerReport";
import Queues from "./components/Queues/Queues/Queues";
import MulticityFlightInfo from "./components/MultiCitySearchResult/MulticityFlightInfo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import SearchHistory from "./components/SearchHistory/SearchHistory";
import Home from "./pages/Home/Home";
import ForgetPassword from "./components/ResetPassword/ForgetPassword";
import { QueryClient, QueryClientProvider } from "react-query";
import PnrImport from "./components/PnrImport/PnrImport";
import Staff from "./components/Staff/Staff";
import AddStaff from "./components/AddStaff/AddStaff";
import SessionTimeout from "./components/SessionTimeOut/SessionTimeOut";
import HotelDataLoad from "./components/Hotel/HotelAfterSearch/HotelDataLoad";

const queryClient = new QueryClient();

function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            {/* //todo set Session time out here it set 15 min 15*60*1000=900000 */}
            <SessionTimeout sessionTimeout={900000} />
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/" element={<SignIn />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signin/:email/:password" element={<SignIn />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetpassword" element={<ForgetPassword />} />
              {/* user dashboard  start */}
              <Route
                path="/user"
                element={
                  <PrivateRoute>
                    <UserDashboardSideBar />
                  </PrivateRoute>
                }
              >
                <Route path="/user/dashboard" element={<Dashboard />} />
                <Route path="/user/home" element={<Home />} />
                {/* After searchResult start  */}
                <Route path="/user/searchresult" element={<SearchResult />} />
                <Route
                  path="/user/roundsearchresult"
                  element={<RoundSearchResult />}
                />
                <Route
                  path="/user/multicityaftersearch"
                  element={<MultiCitySearchResult />}
                />
                <Route
                  path="/user/multicityflightinfo"
                  element={<MulticityFlightInfo />}
                />
                {/* After searchResult end  */}
                {/* After Booking start */}
                <Route
                  path="/user/flightinformation"
                  element={<FlightInformation />}
                />
                <Route
                  path="/user/roundflightinformation"
                  element={<RoundFlightUserInfo />}
                />

                <Route
                  path="/user/congratulation"
                  element={
                    <PrivateRoute>
                      <Congratulation />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/user/searchhistory"
                  element={
                    <PrivateRoute>
                      <SearchHistory />
                    </PrivateRoute>
                  }
                />
                <Route path="/user/queues" element={<Queues />} />
                <Route path="/user/pnrimport" element={<PnrImport />} />
                <Route
                  path="/user/queuesdetail"
                  element={
                    <PrivateRoute>
                      <Congratulation />
                    </PrivateRoute>
                  }
                />

                <Route path="/user/traveller" element={<Traveller />} />
                <Route path="/user/addtraveller" element={<AddTraveller />} />
                {/* <Route path="/user/others" element={<Others />} /> */}
                {/* <Route path="/user/bankaccount" element={<BankAccount />} /> */}
                <Route path="/user/addbankaccount" element={<AddBank />} />
                {/* //todo: add staff  */}
                <Route path="/user/staff" element={<Staff />} />
                <Route path="/user/addstaff" element={<AddStaff />} />

                <Route path="/user/deposit" element={<Deposite />} />
                <Route path="/user/adddeposit" element={<AddDeposite />} />
                <Route
                  path="/user/transection"
                  element={<TransectionReport />}
                />
                <Route path="/user/ledger" element={<LedgerReport />} />

                {/* Hotel Route Start -------------- */}
                <Route
                  path="/user/hotelSearchResult"
                  element={<HotelDataLoad />}
                ></Route>
                {/* Hotel End -------------- */}
              </Route>
              {/* user dashboard  end */}
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
