import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import commaNumber from "comma-number";
import AddIcon from "@mui/icons-material/Add";
import secureLocalStorage from "react-secure-storage";
import RemoveIcon from "@mui/icons-material/Remove";
import "./FlightInfoDetails.css";
import { useState } from "react";

const FlightInfoDetails = ({
  flightData,
  loadData,
  adultCount,
  childCount,
  infantCount,
  isLoaded,
  setIsLoaded,
}) => {
  const users = secureLocalStorage.getItem("user-info");
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box style={{ marginTop: "20px" }}>
      {/*  //TODO:price break down start here */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          boxShadow: "none",
          borderRadius: "0px",
          border: "2px solid var(--gray)",
        }}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{
            background: "var(--gray)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              height: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "var(--white)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Price Breakdown
            </Typography>
            <Box>
              {expanded === "panel1" ? (
                <RemoveIcon style={{ color: "#fff", fontSize: "25px" }} />
              ) : (
                <AddIcon style={{ color: "#fff", fontSize: "25px" }} />
              )}
            </Box>
          </Box>
        </AccordionSummary>
        {/* Total Payable Amount */}
        <AccordionDetails className="flight-accordian2">
          <Box>
            <Typography
              style={{
                color: "var(--black)",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Total Payable
            </Typography>
            <Typography
              style={{
                color: "var(--black)",
                fontFamily: "poppins",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              {/* {commaNumber(totalFares)} SAR */}
            </Typography>
          </Box>
          <Box>
            {flightData?.PriceBreakDown?.map((data) => (
              <Box mb={2}>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(-third-color)",
                    fontFamily: "poppins",
                    fontWeight: "500",
                  }}
                >
                  {data?.PaxType === "ADT"
                    ? "Adult"
                    : data?.PaxType === "CNN"
                    ? "Child"
                    : "Infant"}{" "}
                  x {data?.PaxCount}
                </Typography>

                <Grid container justifyContent="space-between">
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "var(-third-color)",
                      fontFamily: "poppins",
                      fontWeight: "500",
                    }}
                  >
                    Base
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "var(--secondary-color)",
                      fontFamily: "poppins",
                      fontWeight: "500",
                    }}
                  >
                    {commaNumber(
                      parseInt(data?.BaseFare) * parseInt(data?.PaxCount)
                    )}{" "}
                    SAR
                  </Typography>
                </Grid>

                <Grid container justifyContent="space-between">
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#222222",
                      fontFamily: "poppins",
                      fontWeight: "500",
                    }}
                  >
                    Taxes
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "var(--secondary-color)",
                      fontFamily: "poppins",
                      fontWeight: "500",
                    }}
                  >
                    {commaNumber(
                      parseInt(data?.Taxes) * parseInt(data?.PaxCount)
                    )}{" "}
                    SAR
                  </Typography>
                </Grid>

                <Grid container justifyContent="space-between">
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#22222",
                      fontFamily: "poppins",
                      fontWeight: "500",
                    }}
                  >
                    Total Fare
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "var(--secondary-color)",
                      fontFamily: "poppins",
                      fontWeight: "500",
                    }}
                  >
                    {commaNumber(
                      parseInt(data?.TotalFare) * parseInt(data?.PaxCount)
                    )}{" "}
                    SAR
                  </Typography>
                </Grid>
              </Box>
            ))}
          </Box>
          <Box my={2} height="2px" bgcolor="#DEDEDE"></Box>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Total PAX
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {adultCount + childCount + infantCount}&#128100;
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Total Base Fare
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {commaNumber(flightData?.BaseFare)} SAR
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Total TAX
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {commaNumber(flightData?.Taxes)} SAR
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Agent Total
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {commaNumber(flightData?.NetFare)} SAR
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* //TODO: price break down end here */}
      {/* //TODO: Baggage Policy */}

      {/* //TODO: End Baggage Policy */}
      {/* //Todo: Cancellation Policy Section */}

      {/* //Todo: End Cancellation Policy Section */}
    </Box>
  );
};

export default FlightInfoDetails;
