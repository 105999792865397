import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import AccCreated from "../../images/undraw/undraw_happy_announcement_re_tsm0.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import Footer from "../Footer/Footer";
import { LandingHeader } from "../LandingHeader/LandingHeader";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NoEncryptionGmailerrorredOutlinedIcon from "@mui/icons-material/NoEncryptionGmailerrorredOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import loginBg from "../../images/loginPage/loginBg.jpg";
import Logo from "../../images/logo/logo.png";
import PhoneIcon from "@mui/icons-material/Phone";

import "./SingUp.css";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [checked, setChecked] = useState(false);
  const [registerData, setRegisterData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    cPassword: "",
    company: "",
    address: "",
  });
  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newRegisterData = { ...registerData };
    newRegisterData[field] = value;
    setRegisterData(newRegisterData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch("https://api.flyjatt.com/v1/Agent/index.php?add", {
      method: "POST",
      body: JSON.stringify(registerData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status.toLowerCase() === "success") {
          Swal.fire({
            imageUrl: AccCreated,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Account Created Successfully!",
            html: `<div>Dear: <strong>${registerData.firstname} ${registerData.lastname}</strong> Your Account: <strong>${registerData.company}</strong> is Successfully Created.Your Account will be Active within 24 Hours.</div>
            <strong>For any Query contact contact@flyjatt.com or call <strong>+971509517784</strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            setIsLoading(false);
            navigate("/");
          });
        } else {
          Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data?.message,
            html: `<strong>For any Query contact contact@flyjatt.com or call<strong>+971509517784.</strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Please Try Again!",
          }).then(function () {
            setIsLoading(false);
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Server Down",
          html: `<strong>For any Query contact contact@flyjatt.com or call <strong>+971509517784.</strong>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Again!",
        }).then(function () {
          setIsLoading(false);
          navigate(0);
        });
      });
    e.target.reset();
  };

  return (
    <Box>
      <Grid container height>
        <Grid
          item
          display={{ xs: "none", sm: "none", md: "block" }}
          md={6}
          width="100%"
        >
          <Box className="bg-images" height="100vh">
            <Box
              sx={{
                height: "60px",
                // width: "180px",
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: "200px",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box className="login-box" position="relative">
            <Box
              sx={{
                mt: { xs: 3, md: 0 },
                pt: 2,
                display: { xs: "block", md: "none" },
                textAlign: "center",
              }}
            >
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: "200px",
                }}
              />
            </Box>

            <Box
              sx={{
                padding: { md: "0px", sm: "0px 10px", xs: "0px 10px" },
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "98%", sm: "90%", md: "75%" },
              }}
            >
              <Box
                sx={{
                  // height: "fit-content",
                  background: "var(--white)",
                  padding: "0px",
                  borderRadius: "20px",
                }}
              >
                <form onSubmit={handleSubmit} autocomplete="off">
                  <Box
                    my={2}
                    sx={{
                      width: "100%",
                      height: "60px",
                      background: "var(--primary-color)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "20px 20px 0px 0px",
                      // marginTop: { xs: "20px", md: "0px" },
                    }}
                  >
                    <Typography
                      style={{
                        color: "var(--secondary-color)",
                        fontSize: "20px",
                        fontWeight: "normal",
                        textTransform: "capitalize",
                      }}
                    >
                      Agent Registration
                    </Typography>
                  </Box>
                  <Grid container spacing={1} sx={{ padding: "0px 20px 20px" }}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        style={{
                          color: "var(--secondary-color)",
                          fontSize: "14px",
                          fontWeight: "normal",
                          textTransform: "capitalize",
                          textAlign: "center",
                        }}
                      >
                        Registration with Fly Jatt as an agent Please make sure
                        that you Enter the correct information.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          type="text"
                          maxLength={20}
                          placeholder="First Name"
                          name="firstname"
                          value={registerData?.firstname}
                          autocomplete="off"
                          onChange={handleOnChange}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        <PermIdentityOutlinedIcon
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          type="text"
                          name="lastname"
                          maxLength={20}
                          placeholder="Last Name"
                          autocomplete="off"
                          onChange={handleOnChange}
                          value={registerData?.lastname}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        <PermIdentityOutlinedIcon
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={12}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          type="text"
                          placeholder="Company Name"
                          name="company"
                          autocomplete="off"
                          onChange={handleOnChange}
                          value={registerData?.company}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        <BusinessCenterOutlinedIcon
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          type="text"
                          placeholder="Company Address"
                          name="address"
                          value={registerData?.address}
                          autoComplete="off"
                          onChange={handleOnChange}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        <LocationOnOutlinedIcon
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          name="phone"
                          placeholder="Enter Phone Number"
                          value={registerData?.phone}
                          onChange={handleOnChange}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        <CallOutlinedIcon
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          type="email"
                          name="email"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                          placeholder="Email Address"
                          value={registerData?.email}
                          onChange={handleOnChange}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        <EmailOutlinedIcon
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Choose Your Password "
                          autocomplete="off"
                          value={registerData.password}
                          onChange={handleOnChange}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />
                        {showPassword ? (
                          <LockOutlinedIcon
                            onClick={handleClickShowPassword}
                            sx={{
                              color: "var(--primary-color)",
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translate(-5px,-50%)",
                            }}
                          />
                        ) : (
                          <NoEncryptionGmailerrorredOutlinedIcon
                            onClick={handleClickShowPassword}
                            sx={{
                              color: "var(--primary-color)",
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translate(-5px,-50%)",
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        pl={1}
                        color="red"
                        style={{ fontSize: "14px" }}
                        margin="10px 0px"
                      >
                        {registerData.password.length < 8
                          ? "*Password at least 8 characters"
                          : null}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Box sx={{ position: "relative" }}>
                        <input
                          required
                          name="cPassword"
                          type={showPassword ? "text" : "password"}
                          placeholder="Confirm Password "
                          value={registerData?.cPassword}
                          onChange={handleOnChange}
                          style={{
                            height: "40px",
                            width: "calc(100% - 20px)",
                            outline: "none",
                            border: "1px solid black",
                            borderRadius: "20px",
                            padding: "0px 10px",
                            color: "var(--secondary-color)",
                            background: "transparent",
                            fontSize: "16px",
                          }}
                        />

                        {showPassword ? (
                          <LockOutlinedIcon
                            onClick={handleClickShowPassword}
                            sx={{
                              color: "var(--primary-color)",
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translate(-5px,-50%)",
                            }}
                          />
                        ) : (
                          <NoEncryptionGmailerrorredOutlinedIcon
                            onClick={handleClickShowPassword}
                            sx={{
                              color: "var(--primary-color)",
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translate(-5px,-50%)",
                            }}
                          />
                        )}
                      </Box>
                      <Box color="red" fontSize="12px">
                        {registerData.password === registerData.cPassword
                          ? ""
                          : "*Passwords do not match"}
                      </Box>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          sx={{ color: "var(--primary-color)" }}
                          type="checkbox"
                          control={
                            <Checkbox
                              checked={checked}
                              onClick={() => setChecked((prev) => !prev)}
                              sx={{ color: "black", marginLeft: "5px" }}
                            />
                          }
                          label="Agree Terms and Conditions"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Button
                        type="submit"
                        disabled={isLoading ? true : false}
                        sx={{
                          width: "100%",
                          borderRadius: "20px",
                          background: "var(--primary-color)",
                          color: "var(--white)",
                          "&:hover": {
                            background: "var(--primary-color)",
                            color: "var(--white)",
                          },
                        }}
                      >
                        {isLoading ? (
                          <CircularProgress
                            style={{ height: "20px", width: "20px" }}
                          />
                        ) : (
                          "Register →"
                        )}
                      </Button>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "var(--primary-color)",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        Already have account?
                        <Link
                          to="/signin"
                          style={{
                            textDecoration: "none",
                            color: "var(--black)",
                          }}
                        >
                          Login
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUp;
