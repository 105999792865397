import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Success from "../../images/undraw/undraw_completed_tasks_vs6q.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import Delete from "../../images/undraw/undraw_throw_away_re_x60k.svg";
import AddStaffImg from "../../images/undraw/undraw_hire_re_gn5j.svg";

const AddStaff = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formInfo, setFormInfo] = useState({
    agentId,
    Name: "",
    Email: "",
    Designation: "",
    Phone: "",
    Role: "",
    Password: "",
  });
  const handleChange = (e) => {
    setFormInfo({
      ...formInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let url = "https://api.flyjatt.com/v1/Staff/index.php?add";
    let body = JSON.stringify(formInfo);
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          setIsSubmitting(false);
          e.target.reset();
          Swal.fire({
            imageUrl: AddStaffImg,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Staff added successfully",
            html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate("/user/staff");
          });
        } else {
          throw new Error(data.message);
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        e.target.reset();
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Again",
        }).then(() => {
          navigate("/user/staff");
        });
      });
    e.target.reset();
  };
  return (
    <Box>
      <Header />
      <Container sx={{ mt: { xs: 4, sm: 3, md: 2 } }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              mb: "5px",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: { xs: 20, sm: 24 },
              }}
            >
              Add New Staff
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                label: {
                  fontSize: "14px",
                  color: "var(--secondary-color)",
                  fontWeight: "500",
                },
                input: {
                  outline: "none",
                  width: "100%",
                  border: "1px solid var(--secondary-color)",
                  color: "var(secondary-color)",
                  fontSize: "14px",
                  borderRadius: "4px",
                  padding: "5px 10px",
                },
                select: {
                  outline: "none",
                  width: "100%",
                  border: "1px solid var(--secondary-color)",
                  color: "var(secondary-color)",
                  fontSize: "14px",
                  borderRadius: "4px",
                  padding: "5px 10px",
                },
                ".MuiTypography-root": {
                  fontSize: "14px ",
                  color: "var(--primary-color)",
                  textDecoration: "underline",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>Staff Information</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="Name">
                    <span style={{ color: "red" }}>*</span>Staff Name
                  </label>
                  <input
                    required
                    id="Name"
                    name="Name"
                    type="text"
                    placeholder="Staff Name"
                    value={formInfo?.Name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="Phone">
                    <span style={{ color: "red" }}>*</span>Staff Phone
                    <span style={{ color: "red", fontSize: "10px" }}>
                      (enter valid number)
                    </span>
                  </label>
                  <input
                    required
                    id="Phone"
                    name="Phone"
                    placeholder="Phone Number"
                    value={formInfo?.Phone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="Designation">
                    <span style={{ color: "red" }}>*</span>Staff Designation
                  </label>
                  <input
                    required
                    id="Designation"
                    name="Designation"
                    type="text"
                    placeholder="Designation"
                    value={formInfo?.Designation}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Typography>Login Information</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="Email">
                    <span style={{ color: "red" }}>*</span>Email
                  </label>
                  <input
                    required
                    id="Email"
                    name="Email"
                    type="email"
                    placeholder="Your Email"
                    value={formInfo?.Email}
                    autoComplete="new-email"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="Password">
                    <span style={{ color: "red" }}>*</span>Password
                  </label>
                  <Box position={"relative"}>
                    <input
                      required
                      id="Password"
                      name="Password"
                      type={showPassword ? "text" : "password"}
                      placeholder=" Enter Your Password "
                      value={formInfo?.Password}
                      onChange={handleChange}
                      autoComplete="new-password"
                    />

                    {showPassword ? (
                      <Visibility
                        onClick={() => setShowPassword((prev) => !prev)}
                        sx={{
                          color: "var(--primary-color)",
                          position: "absolute",
                          top: "50%",
                          right: "5px",
                          transform: "translate(-5px,-50%)",
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={() => setShowPassword((prev) => !prev)}
                        sx={{
                          color: "var(--primary-color)",
                          position: "absolute",
                          top: "50%",
                          right: "5px",
                          transform: "translate(-5px,-50%)",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="Role">
                    <span style={{ color: "red" }}>*</span>Select Role
                  </label>
                  <select
                    required
                    id="Role"
                    name="Role"
                    value={formInfo?.Role}
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    <option value="staff">Staff</option>
                  </select>
                </Grid>
                <Grid container item justifyContent={"start"}>
                  <Button
                    variant="contained"
                    style={{
                      color: "#fff",
                      backgroundColor: "var(--primary-color)",
                      outline: "none",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      padding: "5px 20px",
                      fontSize: "14px",
                    }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default AddStaff;
