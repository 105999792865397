import React from "react";
import { Box, Typography, Stack, Grid, Button } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PoolTwoToneIcon from "@mui/icons-material/PoolTwoTone";
import BabyChangingStationTwoToneIcon from "@mui/icons-material/BabyChangingStationTwoTone";
import WifiTwoToneIcon from "@mui/icons-material/WifiTwoTone";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--primary-color)",
    maxWidth: "auto",
    padding: "8px",
  },
}));

const HotelSearchResult = ({ hotelData }) => {
  const amenities = ["pool", "wifi", "gym", "restaurant"];

  return (
    <Box>
      <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
        {/* image first section */}
        <Grid item xs={12} sm={3} md={2.5}>
          <Box sx={{ margin: "auto" }}>
            <img
              src={hotelData?.Logo}
              alt="hotel"
              style={{
                width: "100px",
                // height: "120px",
                // objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </Box>
        </Grid>
        {/* Body middle Seaction   */}
        <Grid item xs={12} sm={6} md={6.5}>
          <Stack
            direction="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box>
              <Tooltip title={hotelData?.HotelName}>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: { xs: 12, sm: 14, md: 18 },
                    fontWeight: 500,
                  }}
                  noWrap
                  followCursor
                >
                  {hotelData?.HotelName}
                </Typography>
              </Tooltip>
              <Stack direction="row" spacing={1}>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: { xs: 12, sm: 13, md: 14 },
                    fontWeight: 500,
                  }}
                  noWrap
                >
                  {hotelData?.HotelLocations?.Address?.CityName?.value}
                  {", "}
                  {hotelData?.HotelLocations.Address.CountryName.Code}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--fontcolor)",
                    fontSize: { xs: 12, sm: 13, md: 14 },
                  }}
                  noWrap
                >
                  {hotelData?.HotelLocations?.Address?.AddressLine1}
                </Typography>
              </Stack>
            </Box>

            <Box>
              <Stack direction="row" spacing={1}>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: { xs: 14 },
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  noWrap
                >
                  {hotelData?.IsRefundable ? "Refundable" : "Non Refundable"}
                </Typography>
                <Tooltip
                  title={hotelData?.Included?.map((data) => (
                    <Typography
                      sx={{
                        color: "#FFF",
                        fontSize: { xs: 12 },
                        fontWeight: 500,
                        mb: 1,
                        borderBottom: "1px solid var(--secondary-color)",
                      }}
                    >
                      {/* Code: {data?.Code} */}
                      Description: {data?.Description}
                      <br />
                      {/* {data?.Value !== "" ? `Value: ${data?.Value}` : null} */}
                    </Typography>
                  ))}
                >
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: { xs: 14 },
                      fontWeight: 500,
                      cursor: "pointer",
                      borderRadius: "20px",
                      px: 2,
                      bgcolor: "var(--input-bgcolor)",
                    }}
                    noWrap
                  >
                    Included
                  </Typography>
                </Tooltip>
              </Stack>
              <Grid container spacing={1} alignItems="end">
                <Grid item xs={12} sm={5}>
                  <Box>
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: { xs: 12, sm: 13 },
                        fontWeight: 500,
                        mt: 0.5,
                      }}
                      noWrap
                    >
                      <Stack direction={"row"} alignItems={"center"}>
                        <PhoneIcon sx={{ fontSize: { xs: 14 } }} />
                        &nbsp;&nbsp;Phone:{" "}
                        {hotelData?.HotelLocations?.Contact?.Phone}
                      </Stack>
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: { xs: 12 },
                        fontWeight: 500,
                      }}
                      noWrap
                    >
                      {hotelData?.HotelLocations?.Contact?.Fax !== "" ? (
                        <Stack direction={"row"} alignItems={"center"}>
                          <FaxIcon sx={{ fontSize: { xs: 14 } }} />
                          &nbsp;&nbsp;FAX:{" "}
                          {hotelData?.HotelLocations?.Contact?.Fax}
                        </Stack>
                      ) : null}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {amenities?.map((data) => (
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: { xs: 12, sm: 12 },
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {data === "spa" ? (
                          <BabyChangingStationTwoToneIcon
                            sx={{ fontSize: 16 }}
                          />
                        ) : data === "gym" ? (
                          <FitnessCenterIcon sx={{ fontSize: 16 }} />
                        ) : data === "pool" ? (
                          <PoolTwoToneIcon sx={{ fontSize: 16 }} />
                        ) : data === "restaurant" ? (
                          <RestaurantIcon sx={{ fontSize: 16 }} />
                        ) : data === "wifi" ? (
                          <WifiTwoToneIcon sx={{ fontSize: 16 }} />
                        ) : (
                          <JoinInnerIcon sx={{ fontSize: 16 }} />
                        )}
                        &nbsp;
                        {data}
                      </Typography>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
        {/* price end Section  */}
        <Grid item xs={12} sm={3} md={3} textAlign={"right"}>
          <Stack
            direction="column"
            height="100%"
            justifyContent="space-between"
            alignItems={"end"}
          >
            <Stack direction="row" spacing={1} pb={2}>
              <Box>
                {/* <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {hotelData?.reviewText}
                </Typography> */}
                {/* <Typography
                  sx={{
                    color: "var(--fontcolor)",
                    fontSize: 14,
                  }}
                >
                  {hotelData?.reviewCount}
                </Typography> */}
              </Box>
              <Box
                sx={{
                  bgcolor: "var(--primary-color)",
                  color: "var(--white)",
                  p: 1.5,
                  fontSize: 14,
                }}
              >
                {hotelData?.Ratings}
              </Box>
            </Stack>
            <Box>
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: { xs: 12, sm: 14, md: 18 },
                    textDecoration: "line-through",
                  }}
                >
                  {hotelData?.ClientPrice}&nbsp;
                  {"BDT"}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: { xs: 16, sm: 18, md: 22 },
                    fontWeight: 500,
                  }}
                >
                  {hotelData?.AgentPrice}&nbsp;
                  {"BDT"}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: "var(--fontcolor)",
                  fontSize: { xs: 12, sm: 14, md: 14 },
                  fontWeight: 500,
                }}
              >
                for 1 Night, per Room
              </Typography>
            </Box>

            <Button
              sx={{
                color: "var(--primary-color)",
                bgcolor: "var(--secondary-color)",
                "& hover": {
                  bgcolor: "var(--secondary-color)",
                },
                width: "100%",
              }}
            >
              See Availability
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HotelSearchResult;
