import {
  Box,
  Button,
  Collapse,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import format from "date-fns/format";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import GroupsIcon from "@mui/icons-material/Groups";
import secureLocalStorage from "react-secure-storage";
// import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import flightData from "../../flightData";
import { useRef } from "react";
import { addDays } from "date-fns";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #003566",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const HotelSearchBar = ({
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
}) => {
  const data = flightData; // json data from flight Data

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "yyyy-MM-dd")
  );
  console.log(departureDate);
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "yyyy-MM-dd")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  const now = useRef(new Date());
  const [changeFrom, setChangeFrom] = useState(null);
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [faddress, setfaddress] = useState("Dhaka,BANGLADESH");
  const [fromSendData, setFromSendData] = useState("DAC");
  const [fromSearchText, setFromSearchText] = useState(
    "Dhaka,BANGLADESH (DAC)"
  );
  //  show the form data when click input field
  const initialData = [
    {
      code: "RUH",
      Address: "Riyadh,SAUDI ARABIA",
    },
    { code: "JED", name: "Jeddah Intl ", Address: "Jeddah,SAUDI ARABIA" },
    {
      code: "ABT",
      Address: "Al Baha,SAUDI ARABIA",
    },
    {
      code: "AHB",
      Address: "Abha,SAUDI ARABIA",
    },
    { code: "AJF", name: "JOUF Airport", Address: "Al Jouf,SAUDI ARABIA" },
    {
      code: "AQI",
      Address: "Qaisumah,SAUDI ARABIA",
    },
    { code: "DHA", name: "Dhahran Intl ", Address: "Dhahran,SAUDI ARABIA" },
    {
      code: "DMM",
      Address: "Dammam,SAUDI ARABIA",
    },
    {
      code: "DWD",
      Address: "Dawadmi Intl Airport,SAUDI ARABIA",
    },
  ];

  const [fromSuggest, setFromSuggest] = useState(initialData);
  const [toSuggest, setToSuggest] = useState(initialData);

  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState("");
  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  // Date picker
  const [openDate, setOpenDate] = useState(false);
  const [openReturnDate, setOpenReturnDate] = useState(false);

  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state
  //formOnChange Filter
  const formOnChange = (e) => {
    setOpen(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromSuggestedText = (code, address) => {
    setFromSendData(code);
    setFromSearchText(`${address} [${code}]`);
    setFromSuggest([]);
    setfaddress();
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(true);
  };

  const fromGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "var( --secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(`${item.code}`, `${item.Address}`);
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "12px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "var(--white)",
                        }}
                      >
                        {/* {item.name} */}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // SearchingField End

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen((prev) => !prev);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpenReturnDate(false);
  };
  const handleClickAway = () => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpenReturnDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  const handleClose = () => {
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
    }
  }

  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
        }
      }
    }
  }

  function childInclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  }

  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
      }
    }
  }

  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
    }
  }

  async function handleSearch() {
    if (fromSendData) {
      navigate("/user/hotelSearchResult", {
        state: {
          faddress,
          departureDate: format(new Date(from), "yyyy-MM-dd"),
          returningDate: format(new Date(to), "yyyy-MM-dd"),
          adultCount,
          childCount,
          infant,
          fromSendData,
          fromSearchText,
          className,
          changeFrom,
        },
      });
    } else {
      Swal.fire({
        icon: "error ",
        html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
        confirmButtonText: "Search Again...",
        confirmButtonColor: "var(--primary-color)",
      }).then(() => {
        // setClick(false);
        navigate("/user/dashboard");
      });
    }
  }

  const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
    //console.log(changeFrom);
    if (!changeFrom) {
      setFrom(startDate);
      setTo(startDate);
      if (startDate !== endDate) {
        setTo(endDate);
        setOpenDate(false);
        setTimeout(() => setOpen(true), 200);
      }
    } else {
      setTo(startDate);
      setChangeFrom(false);
      setOpenDate(false);
      setTimeout(() => setOpen(true), 200);
    }
  });

  const ranges = useMemo(() => {
    return [
      {
        startDate: from,
        endDate: to,
        key: "selection",
      },
    ];
  }, [from, to]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box style={{ position: "relative" }}>
        <form>
          <Grid
            sx={{
              height: "fit-content",
              width: "100%",
            }}
            container
            rowSpacing={{ md: 0, sm: 1, xs: 1 }}
            columnSpacing={0}
          >
            {/* //todo destination section */}
            <Grid
              container
              alignItems="center"
              md={4}
              style={{
                height: "90px",
                background: "var(--white)",
                borderRadius: "10px",
              }}
            >
              {/* //todo: Departure City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  position: "relative",
                  border: "1px solid var(--gray)",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "5px",
                  height: "50%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    gap: "5px",
                  }}
                  onClick={() => {
                    setOpenFrom((prev) => !prev);
                    setOpenTo(false);
                    setOpenDate(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white)",
                      }}
                    >
                      <FlightTakeoffIcon sx={{ fontSize: "16px" }} />
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: "90%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {/* <Tooltip title={`Departure From: ${fromSearchText}`}> */}
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "var(--black)",
                        }}
                        noWrap
                      >
                        {fromSearchText}
                      </Typography>
                    </Box>
                    {/* </Tooltip> */}
                  </Box>
                </Box>
                <Collapse
                  in={openFrom}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%" }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      top: "90%",
                      left: "0",
                      right: "0",
                      width: "96%",
                      backgroundColor: "var( --secondary-color)",
                      height: "fit-content",
                      borderBottom: "1px solid var(  --gray)",
                      borderLeft: "1px solid var(  --gray)",
                      borderRight: "2px solid var(  --gray)",
                      borderRadius: "0px 0px 5px 5px",
                      zIndex: "999",
                      padding: "3px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--secondary-color)",
                        zIndex: 10,
                      }}
                      backgroundColor="#fff"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={formOnChange}
                        placeholder="Search a airport..."
                        className="customPlaceholder"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{fromGetSuggetion()}</Box>
                  </Box>
                </Collapse>
              </Grid>
              {/* //todo: Arrival City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  position: "relative",
                  border: "1px solid var(--gray)",
                  borderTop: "none",
                  borderRadius: "0px 0px 10px 10px",
                  padding: "5px",
                  height: "50%",
                }}
              ></Grid>
            </Grid>
            {/* //todo: Departure Travel Date */}
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              sx={{
                position: "relative",
                padding: {
                  md: "0px 10px",
                  sm: "0px",
                  xs: "0px",
                },
                height: "90px",
              }}
            >
              <Box
                style={{
                  border: "1px solid var(--gray)",
                  borderRadius: "10px",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--white)",
                }}
                onClick={() => {
                  setTimeout(() => setOpenDate((prev) => !prev), 200);
                  setOpenFrom(false);
                  setOpenTo(false);
                  setOpen(false);
                }}
              >
                <Box
                  style={{
                    borderRight: "1px solid var(--gray)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "50%",
                  }}
                >
                  <Box
                    sx={{
                      width: "20%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <CalendarMonthIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "10px 0px 0px 10px",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "80%",
                      height: "fit-content",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {`${format(new Date(from), "MMMM")}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "30px",
                        fontWeight: "500",
                        lineHeight: "1",
                      }}
                    >
                      {`${format(new Date(from), "dd")}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {`${format(new Date(from), "EEEE")}`}
                    </Typography>
                  </Box>
                </Box>
                {/* //todo: return date section */}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "50%",
                  }}
                >
                  <Box
                    sx={{
                      width: "20%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <CalendarMonthIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "10px 0px 0px 10px",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "80%",
                      height: "fit-content",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {`${format(new Date(to), "MMMM")}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "30px",
                        fontWeight: "500",
                        lineHeight: "1",
                      }}
                    >
                      {`${format(new Date(to), "dd")}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {`${format(new Date(to), "EEEE")}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {openDate && (
                <Box>
                  <Box
                    sx={{
                      display: {
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                  >
                    <DateRange
                      onChange={handleSelect}
                      direction="horizontal"
                      moveRangeOnFirstSelection={false}
                      retainEndDateOnFirstSelection={false}
                      months={2}
                      ranges={ranges}
                      rangeColors={["#ffa84d"]}
                      minDate={new Date()}
                      className="new-return-date-range"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: {
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                    }}
                  >
                    <DateRange
                      onChange={handleSelect}
                      direction="vertical"
                      moveRangeOnFirstSelection={false}
                      retainEndDateOnFirstSelection={false}
                      months={2}
                      ranges={ranges}
                      rangeColors={["#ffa84d"]}
                      minDate={new Date()}
                      className="new-return-date-range"
                    />
                  </Box>
                </Box>
              )}
            </Grid>
            {/* //todo: Passenger Box section */}
            <Grid
              item
              xs={6}
              sm={6}
              md={2}
              style={{
                position: "relative",
                padding: {
                  md: "0 5px 0 0",
                  sm: "0px",
                  xs: "0px",
                },
                height: "90px",
                zIndex: "9",
              }}
            >
              <Box
                style={{
                  position: "relative",

                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    background: "var(--white)",
                  }}
                  onClick={handleClickOpen}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "50%",
                      border: "1px solid var(--gray)",
                      borderRadius: "10px 10px 0 0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AirlineSeatReclineNormalIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "0px 0px 0px 10px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "0px 0px 0px 10px",
                      }}
                    >
                      {`${
                        className === "S"
                          ? "Premium Economy"
                          : className === "C"
                          ? "Business"
                          : className === "J"
                          ? "Premium Business"
                          : className === "P"
                          ? "First Class"
                          : "Economy"
                      }`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "50%",
                      border: "1px solid var(--gray)",
                      borderTop: "none",
                      borderRadius: "0px 0px 10px 10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <GroupsIcon
                      sx={{
                        fontSize: "35px",
                        color: "var(--primary-color)",
                        padding: "0px 0px 0px 10px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                        padding: "0px 0px 0px 10px",
                      }}
                    >
                      {result} Traveler
                    </Typography>
                  </Box>
                </Box>
                {open && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: { lg: "110%", md: "110%", sm: "100%", xs: "100%" },
                      right: "0px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--secondary-color)",
                        padding: "15px",
                        overflow: "hidden",
                        width: "300px",
                        border: "1px solid var(--gray)",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                      }}
                    >
                      <Box>
                        <Box>
                          <Typography
                            style={{
                              textAlign: "left",
                              marginBottom: "5px",
                              color: "var(--white)",
                              fontFamily: "poppins",
                              fontWeight: "500",
                            }}
                          >
                            Passenger
                          </Typography>
                        </Box>

                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          height="25px"
                          mb={1}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <button
                              onClick={adultDecrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                            <h5 style={{ color: "var(--white)" }}>
                              {adultCount}
                            </h5>
                            <button
                              onClick={adultInclement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",

                              flexDirection: "column",
                              color: "var(--white)",
                            }}
                          >
                            <Typography
                              sx={{ color: "var(--white)", fontSize: "13px" }}
                            >
                              Adult (12+ Years)
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          height="25px"
                          mb={1}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <button
                              onClick={childDecrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                            <h5 style={{ color: "var(--white)" }}>
                              {childCount}
                            </h5>
                            <button
                              onClick={childInclement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",
                              flexDirection: "column",
                              color: "var(--white)",
                            }}
                          >
                            <Typography
                              sx={{ color: "var(--white)", fontSize: "13px" }}
                            >
                              Children (2 - 11 years)
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          height="25px"
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <button
                              onClick={infantDecrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                            <h5 style={{ color: "var(--white)" }}>{infant}</h5>
                            <button
                              onClick={infantIncrement}
                              style={{
                                backgroundColor: "var(--primary-color)",
                                color: "var(--white)",
                                border: "none",
                                width: "25px",
                                height: "25px",
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "start",
                              flexDirection: "column",
                              color: "var(--white)",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "13px", color: "var(--white)" }}
                            >
                              Infant (Under 2 years)
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                      <Box my={1} sx={{ width: "100%" }}>
                        <hr />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            span: {
                              fontSize: 12,
                            },
                          }}
                        >
                          <FormControl>
                            <RadioGroup
                              value={className}
                              row
                              onChange={handleClassName}
                            >
                              <FormControlLabel
                                value="Y"
                                control={<BpRadio />}
                                label="Economy"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="S"
                                control={<BpRadio />}
                                label="Premium Economy"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="C"
                                control={<BpRadio />}
                                label="Business"
                                sx={{
                                  mr: 2.5,
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="J"
                                control={<BpRadio />}
                                label="Premium Business"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                              <FormControlLabel
                                value="P"
                                control={<BpRadio />}
                                label="First Class"
                                sx={{
                                  color: "var(--white)",
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box mt={2} style={{ textAlign: "right" }}>
                          <Button
                            size="small"
                            onClick={handleClose}
                            className="shine-effect"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "var(--white)",
                            }}
                          >
                            DONE
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* //todo: Search Button */}
            <Grid
              md={2}
              sm={12}
              xs={12}
              justifyContent="center"
              alignItems={"center"}
            >
              <Box
                sx={{
                  height: { md: "100%", sm: "70%", xs: "70%" },
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Click To Search">
                  <Button
                    // type="submit"
                    onClick={handleSearch}
                    sx={{
                      height: { md: "100%", sm: "100%", xs: "100%" },
                      width: {
                        md: "90%",
                        sm: "100%",
                        xs: "100%",
                      },
                      borderRadius: "10px",
                      mt: { md: "0px", sm: "10px", xs: "10px" },
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                        color: "var(--white)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {click ? (
                      "Wait..."
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "row", sm: "column" },
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <SearchIcon style={{ fontSize: "40px" }} />
                        <Typography
                          sx={{ fontSize: "16px", color: "var(--white)" }}
                        >
                          Search
                        </Typography>
                      </Box>
                    )}
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ClickAwayListener>
  );
};

export default HotelSearchBar;
