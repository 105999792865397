import { Box, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import "./CashTab.css";

const CashTab = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const agentName = user?.user?.company;
  const [isLoading, setIsLoading] = useState(true);
  const [sender, setSender] = useState(agentName || "");
  const [reciver, setReciver] = useState("Fly Jatt");
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("");
  const [file, setFile] = useState("");

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(false);
    const formData = new FormData();
    formData.append("file", file);

    try {
      let res = await axios.post(
        `https://api.flyjatt.com/v1/Deposit/addDeposit.php?agentId=${agentId}&sender=${sender}&receiver=${reciver}&way=Cash&amount=${amount}&ref=${reference}&actopnBy=Agent`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Deposit Successfully!",
          html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
          button: "Done!",
        }).then(function () {
          setIsLoading(true);
          navigate(-1);
        });
      } else {
        Swal({
          icon: "error",
          title: "Deposit Failed!",
          html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
          button: "Done!",
        }).then(function () {
          setIsLoading(true);
          navigate(0);
        });
      }
    } catch (err) {
      Swal({
        icon: "error",
        title: "Deposit Failed!",
        html: `For any query.Please contact us at <strong> contact@flyjatt.com</strong> or Call <strong> +971509517784 </strong>`,
        button: "Done!",
      }).then(function () {
        setIsLoading(true);
        navigate(0);
      });
    }
    e.target.reset();
  };

  return (
    <Box sx={{ mt: "10px" }}>
      <form onSubmit={handleSubmit}>
        <Box className="passengerInput1">
          <Grid container spacing={2}>
            {/* <Grid item md={4}>
              <Typography>
                Sender Name
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  type="text"
                  placeholder="Sender Name"
                  value={sender}
                  onChange={(e) => setSender(e.target.value)}
                />
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Enter Amount
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  type="text"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Reference
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  type="text"
                  placeholder="Reference"
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <Typography>Remarks</Typography>
              <Box style={{ marginTop: "5px" }}>
                <input
                  required
                  type="text"
                  placeholder="Remarks"
                  value={reciver}
                  onChange={(e) => setReciver(e.target.value)}
                />
              </Box>
            </Grid> */}

            <Grid item xs={12} sm={6} md={4}>
              <Typography>
                Attachment Copy
                <Typography component={"span"} sx={{ color: "red" }}>
                  *
                </Typography>
              </Typography>
              <Box className="input-File1">
                <input
                  required
                  style={{
                    borderRadius: "4px",
                    backgroundColor: "#2564B8",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    padding: "0px",
                    boxSizing: "border-box",
                  }}
                  onChange={onChangeFile}
                  className="customFileType"
                  type="file"
                  title="Choose a Image png and jpg please"
                  accept="image/*"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ display: "flex", alignItems: "end", mt: 2 }}
            >
              <Button
                type="submit"
                sx={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  width: "100%",
                  "&:hover": {
                    background: "var(--secondary-color)",
                    color: "var(--white)",
                  },
                }}
              >
                {isLoading ? (
                  "Send Deposit Request"
                ) : (
                  <CircularProgress
                    size="1.5rem"
                    sx={{
                      color: "#fff",
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default CashTab;
