/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Grow } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import commaNumber from "comma-number";
import "./SingleFlight.css";
import MoreFlight from "./MoreFlight";
import FlightLayout from "./FlightLayout";

const SingleFlight = ({
  flightData,
  adultCount,
  childCount,
  infant,
  to,
  from,
  tripType,
  fromAddress,
  toAddress,
  dDate,
  agentFarePrice,
  setAgentFarePrice,
  commisionFarePrice,
  setCommisionFarePrice,
  customerFare,
  setCustomerFare,
}) => {
  const [flightDetails, setFlightDetails] = useState(false);

  const clientPrice = parseInt(
    flightData.System !== "Galileo" ? flightData.clientPrice : flightData.price
  );
  // const percentRate = parseInt(commissionData.defaultCommissionRate) / 100;
  const clientFare = Math.round(clientPrice);
  const agentFare = Math.round(
    parseInt(
      flightData.System !== "Galileo" ? flightData.price : flightData.BasePrice
    )
  );
  const commission = Math.round(clientFare - agentFare);
  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const navigate = useNavigate();
  const FlightInformation = (data) => {
    navigate("/user/flightinformation", {
      state: {
        flightData,
        data,
        adultCount,
        childCount,
        infant,
      },
    });
  };

  let calParcent = (num, percentage) => {
    const result = num * (percentage / 100);
    return parseFloat(result.toFixed(0));
  };
  let percntVal = calParcent(parseInt(flightData.price), 7);

  const offerPrice = parseInt(flightData?.price) + parseInt(percntVal);
  const PaxCount = adultCount + childCount + infant;
  let count = [];
  for (let i = 0; i < PaxCount; i++) {
    count.push(i);
  }

  // ----   --------Copy form ALL.js end----------
  return (
    <Box mb={2.5}>
      <Grid
        container
        sx={{
          boxShadow:
            "-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)",
          borderRadius: "10px",
        }}
      >
        <Grid item md={10} p={2}>
          {flightData?.AllLegs?.map((data, index) => (
            <Box key={index}>
              <FlightLayout flightData={data} allData={flightData} />
            </Box>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          pt={{ xs: 0, sm: 2 }}
          pb={{ xs: 1.5, sm: 2 }}
          pr={2}
          textAlign={{ xs: "center", sm: "right" }}
        >
          <Box textAlign="end" mr={{ xs: "5px", sm: "0px" }}>
            <Typography
              sx={{
                fontSize: "18px",
                color: "var(--secondary-color)",
                fontWeight: 500,
                display: { xs: "none", sm: "block" },
              }}
            >
              SAR {commaNumber(Math.round(flightData?.NetFare))}
            </Typography>
          </Box>

          <Box
            textAlign="end"
            mt={{ xs: 0, sm: 1 }}
            px={{ xs: 2, sm: "0" }}
            display={{ xs: "flex", sm: "block" }}
            justifyContent="space-between"
            alignItems={{ xs: "center", sm: "" }}
          >
            <Button
              size="small"
              style={{
                color: "var(--white)",
                fontWeight: 500,
                backgroundColor: "var(--primary-color)",
                borderRadius: "5px",
              }}
              padding={{ xs: "7px 20px", sm: "" }}
              height={{ xs: "40px", sm: "" }}
              onClick={() => FlightInformation(flightData?.AllLegs)}
            >
              BOOK NOW
            </Button>
            <MoreFlight
              flightData={flightData}
              adultCount={adultCount}
              childCount={childCount}
              infant={infant}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        {flightData?.More?.length > 1 ? (
          <Typography
            sx={{
              fontSize: 12,
              bgcolor: "var(--drawer-bgcolor)",
              width: "fit-content",
              margin: "auto",
              borderRadius: "10px",
              px: 2,
              py: 0.5,
              mt: -1.5,
              cursor: "pointer",
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
            }}
            onClick={() => setFlightDetails(!flightDetails)}
          >
            Show {flightData?.More?.length - 1} more flight timing option for
            the same price
          </Typography>
        ) : null}
        <Grow in={flightDetails} display={!flightDetails ? "none" : "block"}>
          <Box mt={2}>
            {flightData?.More?.slice(1)?.map((data, index) => (
              <Grid
                container
                sx={{
                  boxShadow:
                    "-0.452679px 4.97947px 36px rgba(0, 0, 0, 0.09), -0.0905357px 0.995893px 5.85px rgba(0, 0, 0, 0.045)",
                  borderRadius: "10px",
                  mb: 1,
                }}
              >
                <Grid item md={10} p={2}>
                  <Box>
                    {data?.AllLegs?.map((item, index) => (
                      <FlightLayout
                        flightData={item}
                        index={index}
                        allData={data}
                      />
                    ))}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  pt={{ xs: 0, sm: 2 }}
                  pb={{ xs: 1.5, sm: 2 }}
                  pr={2}
                  textAlign={{ xs: "center", sm: "right" }}
                >
                  <Box textAlign="end" mr={{ xs: "5px", sm: "0px" }}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "var(--secondary-color)",
                        fontWeight: 500,
                        display: { xs: "none", sm: "block" },
                      }}
                    >
                      SAR{commaNumber(Math.round(data?.NetFare))}
                    </Typography>
                  </Box>

                  <Box
                    textAlign="end"
                    mt={{ xs: 0, sm: 1 }}
                    px={{ xs: 2, sm: "0" }}
                    display={{ xs: "flex", sm: "block" }}
                    justifyContent="space-between"
                    alignItems={{ xs: "center", sm: "" }}
                  >
                    <Button
                      size="small"
                      style={{
                        color: "var(--white)",
                        fontWeight: 500,
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "5px",
                      }}
                      padding={{ xs: "7px 20px", sm: "" }}
                      height={{ xs: "40px", sm: "" }}
                      onClick={() => FlightInformation(data)}
                    >
                      BOOK NOW
                    </Button>
                    <MoreFlight flightData={data} index={index} />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grow>
      </Box>
    </Box>
  );
};

export default React.memo(SingleFlight);
